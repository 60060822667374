import { useState, useEffect } from 'react'
import fetchData from '../../../helper/fetchdata'
// import { useAuth } from 'react-oidc-context'
import {useAuth} from '../../../context/useAuth'
import { useHistory, useParams } from 'react-router-dom'
import { useProjects } from '../../../context/ProjectContext'
import AccessDenied from '../../Pages/AccessDenied'
import { Button, Spinner, ToastContainer } from 'react-bootstrap'
import CustomTable from '../../../helper/CustomTable'
// import { useCustomAuth } from '../../../context/AuthContext'
import { toast } from 'react-toastify'
import CustomBreadcrumb from '../../Pages/CustomBreadcrumb'

function PermissionDataByUsers() {

  const auth = useAuth()
  const params: { [key: string]: string | undefined } = useParams()
  //console.log(auth.user)
    const history = useHistory()
    const data = useProjects();
    // const {isAdmin} = useCustomAuth()
    // console.log("is admin is:",isAdmin)
    const [initialLoading, setInitialLoading] = useState<Boolean>(true)
    const [dataList, setDataList] = useState([])
    const [accessDenied, setAccessDenied] = useState(3)

  useEffect(() => {

    if(params.id){
      //console.log(params.id)
      getPermissionDataByUser()
    }
    // if(isAdmin){

    // }

    //getAllUserdata()

    //getPermissionDataByUser()
  }, [])


  // const getAllUserdata = async()=>{
  //   try {
  //      const result = await fetchData(`admin/permissions/all-users`,'get',null,String(auth.user?.id_token),'json')
  //      console.log(result)
  //        if(result.responseCode===200){
  //       setDataList(result.response.data)
  //       setAccessDenied(2)
  //       setInitialLoading(false)
  //      }else if(result.responseCode===403){
  //         setAccessDenied(1)
  //         setInitialLoading(false)
  //       }else{
  //           toast.error("Something went wrong", {
  //           position: "bottom-right",
  //         });
  //          //setInitialLoading(false)
  //       }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const getPermissionDataByUser = async()=>{
    try {

      const body = {
        id:params.id
      }
       const result = await fetchData(`admin/permissions/permission-by-user`,'post',body,String(auth.user?.id_token),'json')
       //console.log(result)

        if(result.responseCode===200){
        setDataList(result.response.data)
        setAccessDenied(2)
        setInitialLoading(false)
       }else if(result.responseCode===403){
          setAccessDenied(1)
          setInitialLoading(false)
        }else{
            toast.error("Something went wrong", {
            position: "bottom-right",
          });
        }


    } catch (error) {
      //console.log(error)
    }
  }

    const headings = ['Project Name','Module Name','Actions']

    const actionOption = [
     {
      type: 2,
      link: "/admin/permission/edit",
      linkedField: "id",
      caption: "Edit Permission",
      permission_field: "perm_update"
      },
      {
    type: 3,
    link: "admin/permissions/delete",
    linkedField: "id",
    caption: "Delete Permission",
    permission_field: "perm_delete"
  }
]

  // const moduleReformattedData=(data:any)=>{
  //   return data.filter(item=>item).map(item=>item.name).join(', ')
  // }

    const generateRows= (data:any)=>{
      return(
        <>
         <td>{data.project?.name}</td>
          <td>{data.module?.name}</td>

          {/* <td>{data.user?.email}</td> */}
          {/* <td>{data.project?.name}</td> */}
          {/* <td>{moduleReformattedData(data.module)}</td> */}

        </>
      )
    }


  return (
     <div className='fluid' style={{margin:'6%'}}>
       {accessDenied===1 && !initialLoading && <AccessDenied url={"/home"} />}
       {initialLoading && <div className="spinner">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div> }
    {accessDenied===2 && !initialLoading && <>
   <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '2%' }}>
    <CustomBreadcrumb list={[{name:'Home', url:'/home', isActive:false},{name:'Permission by User', url:`/permission/view/${params.id}`, isActive:true}]}/>
    <div>
      <Button variant='primary' onClick={()=>history.push(`/admin/permission/add/${params.id}`)}>Add Permission to User</Button>
    </div>
    {/* <div>
      <Button onClick={()=>history.push('/admin/permission/all-user')}>Go Back</Button>
    </div> */}
  </div>
   <ToastContainer />
    <CustomTable headings={headings} generateRows={generateRows} data={dataList} setData={setDataList} routeLinks={actionOption}/>
      </>}
    </div>
  )
}

export default PermissionDataByUsers