import { useState, useEffect } from "react";
import "../../style/index.css";
import { Card, Button, Spinner, Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
// import { useAuth } from "react-oidc-context";
import fetchData from "../../helper/fetchdata";
import { ToastContainer, toast } from "react-toastify";
import { ProjectProvider, useProjects } from "../../context/ProjectContext";
import GoHome from "./GoHome";
import { useAuth } from "../../context/useAuth";
// import { useCustomAuth } from "../../context/CustomAuthProvider";

interface TabListType {
  project_id: number;
  project_name: string;
  project_url?: string;
  project_description: string;
}

function Home() {
  const history = useHistory();
  const data = useProjects();

  const navigateRoute = (item_name: TabListType) => {
    let path = `/project/${item_name.project_id}`;
    if (item_name.project_id === 1) {
      path = `/admin`;
    }
    history.push(path);
  };

  return (
    <Container style={{ marginTop: "5%", marginBottom:'5%' }}>
      <ToastContainer />
      {data?.initialLoading === 3 ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : data?.tabList && data?.tabList.length > 0 ? (
        <Row className="justify-content-center">
          {data?.tabList.map((tabItem, index) => {
            if (tabItem.project_id === 2) return null;

            const isAdminTab = tabItem.project_id === 1;
            return (
              <Col
                key={index}
                md={isAdminTab ? 12 : 4}
                className="d-flex justify-content-center mb-4"
              >
                <Card
                  style={{
                    width: isAdminTab ? "100%" : "25rem",
                    height: !isAdminTab ?"100%":"",
                    border: isAdminTab ? "2px solid #007bff" : "1px solid #dee2e6",
                    backgroundColor: isAdminTab ? "#f8f9fa" : "#ffffff",
                    boxShadow: isAdminTab
                      ? "0 4px 6px rgba(0, 123, 255, 0.3)"
                      : "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Card.Body className="text-center">
                    <Card.Title className={isAdminTab ? "text-primary" : ""}>
                      {tabItem.project_name}
                    </Card.Title>
                    <Card.Text>{tabItem.project_description}</Card.Text>
                    <Button
                      variant={isAdminTab ? "outline-primary" : "primary"}
                      className="m-3"
                      onClick={() => navigateRoute(tabItem)}
                    >
                      {isAdminTab?'Manage Settings':'View Module'}
                    </Button>
                  </Card.Body>
                  {isAdminTab && (
                    <Card.Footer className="text-center">
                      <small className="text-muted">
                        Admin Section - Manage Settings
                      </small>
                    </Card.Footer>
                  )}
                </Card>
              </Col>
            );
          })}
        </Row>
      ) : data?.tabList && data?.initialLoading === 2 ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <GoHome name={"Project"} isHome={true} />
      )}

      {data?.initialLoading === 1 &&
        data?.tabList &&
        data?.tabList.length === 1 &&
        data?.isPermission && (
          <Card style={{ width: "25rem", margin: "1%" }}>
            <Card.Body>
              <Card.Title>You only have Permission Access</Card.Title>
              <Button
                variant="primary"
                onClick={() => history.push("/admin/permission")}
              >
                View Permissions
              </Button>
            </Card.Body>
          </Card>
        )}
    </Container>
  );
}

export default Home;
