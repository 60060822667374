import { useState, useEffect } from 'react'
import fetchData from '../../../helper/fetchdata'
// import { useAuth } from 'react-oidc-context'
import {useAuth} from '../../../context/useAuth'
import { useHistory } from 'react-router-dom'
import { useProjects } from '../../../context/ProjectContext'
import AccessDenied from '../../Pages/AccessDenied'
import { Button, Spinner, ToastContainer } from 'react-bootstrap'
import CustomTable from '../../../helper/CustomTable'
// import { useCustomAuth } from '../../../context/AuthContext'
import { toast } from 'react-toastify'
import CustomBreadcrumb from '../../Pages/CustomBreadcrumb'

function AllUserPermission() {

  const auth = useAuth()

  //console.log(auth.user)
      const history = useHistory()
      const data = useProjects();
          // const {isAdmin} = useCustomAuth()
    // console.log("is admin is:",isAdmin)
    const [initialLoading, setInitialLoading] = useState<Boolean>(true)
    const [dataList, setDataList] = useState([])
    const [accessDenied, setAccessDenied] = useState(3)

  useEffect(() => {
    // if(isAdmin){

    // }

    getAllUserdata()

    //getPermissionDataByUser()
  }, [])


  const getAllUserdata = async()=>{
    try {
       const result = await fetchData(`admin/permissions/all-users`,'get',null,String(auth.user?.id_token),'json')
       //console.log(result)
         if(result.responseCode===200){
        setDataList(result.response.data)
        setAccessDenied(2)
        setInitialLoading(false)
       }else if(result.responseCode===403){
          setAccessDenied(1)
          setInitialLoading(false)
        }else{
            toast.error("Something went wrong", {
            position: "bottom-right",
          });
           //setInitialLoading(false)
        }
    } catch (error) {
      //console.log(error)
    }
  }

  // const getPermissionDataByUser = async()=>{
  //   try {

  //     const body = JSON.stringify({
  //       userId:34
  //     })
  //      const result = await fetchData(`admin/permissions/permission-by-user`,'post',body,String(auth.user?.id_token),'json')
  //      console.log(result)


  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

    const headings = ['User Name','Email','Actions']

    const actionOption = [
      {
      type: 2,
      link: "/admin/permission/view",
      linkedField: "id",
      caption: "View Permission",
      permission_field: "perm_view"
      },
      {
      type: 4,
      link: "/admin/permissions/make-admin",
      linkedField: "id",
      caption: "Make Admin",
      permission_field: "perm_admin"
      },
      {
    type: 3,
    link: "admin/permissions/delete-all-permission",
    linkedField: "id",
    caption: "Delete All Permission",
    permission_field: "perm_all_delete"
  }
]

  // const moduleReformattedData=(data:any)=>{
  //   return data.filter(item=>item).map(item=>item.name).join(', ')
  // }

    const generateRows= (data:any)=>{
      return(
        <>
          <td>{data.name}</td>
          <td>{data.email}</td>
        </>
      )
    }


  return (
     <div className='fluid' style={{margin:'6%'}}>

       {accessDenied===1 && !initialLoading && <AccessDenied url={"/home"} />}
       {initialLoading && <div className="spinner">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div> }
    {accessDenied===2 && !initialLoading && <>
   <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '2%' }}>
    <CustomBreadcrumb list={[{name:'Home', url:'/home', isActive:false},{name:'All Users', url:'/admin/permission/all-user', isActive:false}]}/>
    <div>
      <Button variant='primary' onClick={()=>history.push('/admin/permission/add')}>Add Permission</Button>
    </div>
    {/* <div>
      <Button onClick={()=>history.push('/home')}>Go Back</Button>
    </div> */}
  </div>
   <ToastContainer />
    <CustomTable headings={headings} generateRows={generateRows} data={dataList} setData={setDataList} routeLinks={actionOption}/>
      </>}
    </div>
  )
}

export default AllUserPermission