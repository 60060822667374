const ConvertURLtoCDN = (data:{[key:string]:any})=>{
    let finalUrl = ''
    if(data){
        finalUrl = `${process.env.REACT_APP_CDN_URL}/${data.data.Location.split('/').pop()}`

        //console.log("finalurl is: ", finalUrl)
    }
    // console.log("finalurl is: ", new URL(finalUrl))
    return new URL(finalUrl)
}

export default ConvertURLtoCDN