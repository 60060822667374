import { useEffect } from 'react'
import { useAuth } from '../context/useAuth'
//import { useAuth } from 'react-oidc-context'
import { Redirect, useHistory} from 'react-router-dom';
import fetchData from '../helper/fetchdata';
import { Spinner } from 'react-bootstrap';
import '../style/index.css'
import { User } from 'oidc-client-ts';


function LoginSuccess() {
  const auth = useAuth()
  const history = useHistory()

  useEffect(() => {
     //console.log(auth)
    if(auth.user){
      login()
    }
  }, [auth.user])


  const login = async() => {

      const data = {token: auth.user?.access_token}
      const result = await fetchData('users/login','post',data,String(auth.user?.id_token),'json')
      if(result.responseCode === 200){
        if(result.response.data.isAdmin){
        auth.setAdmin(auth.user as User)
        const isAdmin = result.response.data.isAdmin;
        sessionStorage.setItem('isAdmin', JSON.stringify(isAdmin));
        // const user = new User({ ...auth.user, profile: { ...auth.user.profile, isAdmin: isAdmin } });
        // auth.signinCallback(user)
        }
        history.push("/home")

        // <Redirect to="/home" />
      }else{
        return <Redirect to="/login" />
      }
    }


    return (
      <div>
        {
          //successful authentication(auth.isAuthenticated===true && auth.isLoading===false)
          auth.isAuthenticated && !auth.isLoading && <Redirect to="/home"/>
        }

         {
          //(auth.isAuthenticated===false && auth.isLoading===true)
         !auth.isAuthenticated && auth.isLoading && <div className="spinner"><Spinner animation="border" role="status">
  <span className="visually-hidden">Loading...</span>
</Spinner></div>}
        {
        //(auth.isAuthenticated===false && auth.isLoading===false)
        //!auth.isAuthenticated && !auth.isLoading && <Redirect to="/login"/>
        }
        </div>
    )

}

export default LoginSuccess


// import { useEffect, useMemo, useState, Dispatch } from 'react'
// import { User } from 'oidc-client-ts';
// import { useAuth, AuthState} from 'react-oidc-context'
// import { useHistory} from 'react-router-dom';
// import fetchData from '../helper/fetchdata';
// import { Spinner } from 'react-bootstrap';
// import '../style/index.css'
// // import { useCustomAuth } from '../context/AuthContext';
// //import useCustomAuthStore from '../context/customAuthStore';
// // import {useCustomAuth} from '../context/CustomAuthProvider'


// // interface CustomAuthType extends AuthState{
// //   isAdmin:boolean
// // }

// function LoginSuccess() {
//   const auth= useAuth()
//   const history = useHistory()
//   //  let {extendedAuth: auth,setExtendedAuth}= useCustomAuth()

//    console.log("auth from loginsuvccess: ",auth)
//   //  console.log("auth1 in loginauccess",auth1)



//   useEffect(() => {
//     console.log(auth)
//     //  if(auth.user !== null){
//     //   console.log(" inside home route")
//     //   history.push('/home')
//     //  }else if(auth.user===null){
//     //   console.log("inside logout")
//     //   auth.signoutRedirect({ 'post_logout_redirect_uri': `${process.env.REACT_APP_REDIRECT_URI}/login` })
//     //  }
//     if(auth.user){
//       login()
//     }
//   }, [auth.isAuthenticated])


//   const login = async() => {
//       const data = {token: auth.user?.access_token}
//       const result = await fetchData('users/login','post',data,String(auth.user?.id_token),'json')
//       if(result.responseCode === 200){
//         if(result.response.data){
//           if(result.response.data.isAdmin){
//             // auth = {...auth, isAdmin: result.response.data.isAdmin}
//             // setExtendedAuth(auth)
//             //  setExtendedAuth({...auth,isAdmin: result.response.data.isAdmin})
//             // setIsAdmin(result.response.data.isAdmin)
//             // setIsAdmin(true)
//           }else{
//             // setIsAdmin(false)
//             // setExtendedAuth({...auth,isAdmin: result.response.data.isAdmin})
//           }


//           console.log(auth)
//         }
//         history.push('/home')
//       }else{
//         auth.signoutRedirect({ 'post_logout_redirect_uri': `${process.env.REACT_APP_REDIRECT_URI}/login` })

//       }
//     }


//   //  const login = useMemo(() => async () => {
//   //   const data = { token: auth.user?.access_token };
//   //   const result = await fetchData('users/login', 'post', data, String(auth.user?.id_token), 'json');
//   //   if (result.responseCode === 200) {
//   //     if (result.response.data) {
//   //       setIsAdmin(result.response.data.isAdmin);
//   //     }
//   //     history.push('/home');
//   //   } else {
//   //     auth.signoutRedirect({ post_logout_redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}/login` });
//   //   }
//   // }, [auth.user, setIsAdmin, history]);

//   // useEffect(() => {
//   //   if (auth.user) {
//   //     login();
//   //   }
//   // }, [auth.user]);

//     return (
//       <div>
//          {!auth.isAuthenticated &&
//           <div className="spinner">
//             <Spinner animation="border" role="status"/>
//           </div>
//           }
//         </div>
//     )
// }

// export default LoginSuccess

// import React, { useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
// // import { useAuth } from '../context/CustomAuthProvider';
// import { useAuth } from '../context/useAuth';
// import Spinner from 'react-bootstrap/Spinner';
// import fetchData from '../helper/fetchdata';

// function LoginSuccess() {
//     const auth = useAuth();
//     const history = useHistory();

//     useEffect(() => {
//       console.log("auth is: ", auth)
//         if (auth && auth.user) {
//             login();
//         }
//     }, [auth.user]);

//     const login = async () => {
//         const data = { token: auth.user?.access_token };
//         const result = await fetchData('users/login', 'post', data, String(auth.user?.id_token), 'json');
//         if (auth && result.responseCode === 200) {
//             // const user:any = { ...auth.user, profile: { ...auth.user?.profile, isAdmin: result.response.data.isAdmin }};
//             // auth.signinCallback(user);
//             history.push('/home');
//         } else {
//            // auth.signout();
//            auth.signoutRedirect({ 'post_logout_redirect_uri': `${process.env.REACT_APP_REDIRECT_URI}/login` })
//         }
//     };

//     return (
//         <div>
//             {!auth.isAuthenticated && (
//                 <div className="spinner">
//                     <Spinner animation="border" role="status" />
//                 </div>
//             )}
//         </div>
//     );
// }

// export default LoginSuccess;
