import { useState, useEffect } from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
// import { useAuth } from "react-oidc-context";
import {useAuth} from '../../context/useAuth'
import fetchData from "../../helper/fetchdata";
import { ToastContainer, toast } from "react-toastify";
import "../../style/index.css";
import { useProjects } from "../../context/ProjectContext";
import GoHome from "./GoHome";
import CustomBreadcrumb from "./CustomBreadcrumb";

interface ModuleListType {
  module_description: string;
  project: {
    name: string;
    id: string;
  };
  module_id: number;
  module_name: string;
  module_api_url: string;
}



function ProjectPage() {
  const auth = useAuth();
  const history = useHistory();
  const data = useProjects();
  const params: { [key: string]: string | undefined } = useParams();
  const [moduleData, setModuleData] = useState<ModuleListType[]>([]);
  const [initialLoading, setInitialLoading] = useState(false);

  useEffect(() => {

    //console.log("data is: ", data)
    if (params.id ) {
      getData(params.id);
    }
  }, [data?.initialLoading]);

  //get the project data that the user has access to
  const getData = async (id: string) => {
    setInitialLoading(true);
    try {
      let url = `modules/project-id/${id}`
      if(data?.isSettings){
        url = `admin/modules/project-id/${id}`
      }
      const result = await fetchData(
        url,
        "get",
        null,
        String(auth.user?.id_token),
        "json"
      );

      if (result.responseCode === 200) {
        setModuleData(result.response.data);
      } else {
        toast.error("Something went wrong", {
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
      });
    }
    setInitialLoading(false);
  };

  return (
   <Container style={{ marginTop: "5%" }}>
   <CustomBreadcrumb list={[{name:'Home', url:'/home', isActive:false},{name:'All Modules', url:`/project/{$params.id}`, isActive:true}]}/>
      <ToastContainer />
      {initialLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : moduleData && moduleData.length > 0 ? (
        <Row className="mt-5 justify-content-center">
          {moduleData.map((moduleItem, index) => (
            <Col
              key={index}
              md={6}
              className="d-flex justify-content-center mb-4"
            >
              <Card
                style={{
                  width: "30rem",
                  height:"100%",
                  border: "1px solid #dee2e6",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
                onClick={() =>
                  history.push(`/module/id/${moduleItem.module_id}`)
                }
              >
                <Card.Body className="m-3 text-center">
                  <Card.Title className="m-1 text-center">{moduleItem.module_name}</Card.Title>
                   <Card.Text className="m-3">{moduleItem.module_description}</Card.Text>
                  <Button variant="primary">
                    View API
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <GoHome name={"Module"} />
      )}
    </Container>
  );
}

export default ProjectPage;
