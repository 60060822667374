import React from 'react'
import { Breadcrumb } from 'react-bootstrap'

function CustomBreadcrumb({list}:any) {

  // console.log("list: ", list)
  return (
    <Breadcrumb>
    {/* {list.map((item:any)=><Breadcrumb.Item>{'item.name'}</Breadcrumb.Item>)} */}
    {list.map((item:any, index:any)=>
        <Breadcrumb.Item href={item.url} active={item.isActive}>{item.name}</Breadcrumb.Item>
    )}
    </Breadcrumb>
  )
}

export default CustomBreadcrumb