
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '@stoplight/elements/styles.min.css';
import { API,APIWithStackedLayout } from '@stoplight/elements';

// import { useAuth } from 'react-oidc-context';
import {useAuth} from '../../context/useAuth'
import { Spinner, ToastContainer } from 'react-bootstrap';
import '../../style/index.css'
import AccessDenied from './AccessDenied';
import fetchData from '../../helper/fetchdata';
import { toast } from 'react-toastify';
import '../../style/customStyle.css'
import { RoutingProps } from '@stoplight/elements-core';
import * as React from 'react';
import { useProjects } from '../../context/ProjectContext';
export declare type APIProps = APIPropsWithDocument | APIPropsWithUrl;
export declare type APIPropsWithUrl = {
    apiDescriptionUrl: string;
} & CommonAPIProps1;
export declare type APIPropsWithDocument = {
    apiDescriptionDocument: string | object;
    apiDescriptionUrl?: string;
} & CommonAPIProps1;
export interface CommonAPIProps1 extends RoutingProps {
    layout?: 'sidebar' | 'stacked' | 'responsive';
    logo?: string;
    hideTryIt?: boolean;
    hideSchemas?: boolean;
    hideInternal?: boolean;
    hideExport?: boolean;
    tryItCredentialsPolicy?: 'omit' | 'include' | 'same-origin';
    tryItCorsProxy?: string;
    maxRefDepth?: number;
}
// export declare const APIImpl: React.FC<APIProps>;
// export declare const API: React.FC<APIProps>;

interface UrlDataType{module_name:string,module_api_url:string}



const StoplightAPI = () => {
  const auth = useAuth()
  const data = useProjects();
  const params: {[key:string]:string|undefined}= useParams()
  const [urlData, setUrlData] = useState<UrlDataType>({module_name:'',module_api_url:''})
  const [initialLoading, setInitialLoading] = useState(false)
  const [accessDenied, setAccessDenied] = useState(3)

  useEffect(() => {
    //console.log(data)

    getData()
   }, [])

   const getData = async()=>{
    setInitialLoading(true)
    let url = `modules/get-api-url/id/${params.id}`
    if(data?.isSettings){
       url = `admin/modules/get-api-url/id/${params.id}`
      }
const result = await fetchData(url,'get',null,String(auth.user?.id_token),'json')

     //console.log(result)
      if(result.responseCode===200){
            // setDataList(result.response.data)
            urlData.module_api_url = result.response.data[0].api_url
            urlData.module_name = result.response.data[0].module_name
            setUrlData({...urlData})
            setAccessDenied(2)
            setInitialLoading(false)
      }else if(result.responseCode===403){
          setAccessDenied(1)
          setInitialLoading(false)
        }
        else{
            toast.error("Something went wrong", {
            position: "bottom-right",
          });
           setInitialLoading(false)
        }
   }

  return (
    <>
    {accessDenied===1 && <AccessDenied url={"/home"} />}
       {initialLoading && <div className="spinner">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>}
    {accessDenied===2 && !initialLoading &&
    <div className="custom-sidebar">
     <ToastContainer/>
    <API
    // layout='responsive'
    basePath={`/module/id/${params.id}`}
     apiDescriptionUrl={urlData.module_api_url}
  />
  {/* <APIWithStackedLayout serviceNode={undefined} location={undefined}> */}
  </div>
  }
  </>
  )
};

export default StoplightAPI


