import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import CustomTable from '../../../helper/CustomTable'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
// import { useAuth } from 'react-oidc-context';
import {useAuth} from '../../../context/useAuth'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fetchData from '../../../helper/fetchdata';
import '../../../style/index.css'
import AccessDenied from '../../Pages/AccessDenied';
import { useProjects } from '../../../context/ProjectContext';
import CustomBreadcrumb from '../../Pages/CustomBreadcrumb';

interface PermissionType{
  user:{
    email: string;
    id:number,
  },
  project:{
    id:number,
    name:string
  },
  module:ModulePermissionDataType[]
  }
  interface ModulePermissionDataType{
    id:number,
    name:string
  }

function AllPermission() {
    const auth = useAuth()
    const history = useHistory()
    const data = useProjects();
    const [initialLoading, setInitialLoading] = useState<Boolean>(true)
    const [dataList, setDataList] = useState<PermissionType[]>([])
    const [projectOptions, setProjectOptions] = useState([])
    const [selectedData, setSelectedData] = useState({project_id:null, email:null})
    const [accessDenied, setAccessDenied] = useState(3)


  useEffect(() => {
    //console.log(data)
if(data?.initialLoading===1 && data?.isPermission){
   getData()
   getProjectData()
}
if(data?.initialLoading===1 && !data?.isPermission){
  setAccessDenied(1)
  setInitialLoading(false)
}

//getAllUserdata()
//getPermissionDataByUser()
  }, [data?.initialLoading])

  useEffect(() => {
   if(data?.initialLoading===1 && data?.isPermission){
   getData()
  // getProjectData()
}
  }, [])



  // const getAllUserdata = async()=>{
  //   try {
  //      const result = await fetchData(`admin/permissions/all-users`,'get',null,String(auth.user?.id_token),'json')
  //      console.log(result)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  //   const getPermissionDataByUser = async()=>{
  //   try {

  //     const body = JSON.stringify({
  //       userId:34
  //     })
  //      const result = await fetchData(`admin/permissions/permission-by-user`,'post',body,String(auth.user?.id_token),'json')
  //      console.log(result)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

    //get all the permission data
    const getData = async(data:any = null)=>{
      setInitialLoading(true)
      try {
        let body = data?{...data}:{
          ...selectedData
        }
        const result = await fetchData(`admin/permissions`,'post',body,String(auth.user?.id_token),'json')
        if(result.responseCode===200){
              setDataList(result.response.data)
              setAccessDenied(2)
              setInitialLoading(false)
        }else if(result.responseCode===403){
          // setInitialLoading(true)
          // toast.error("Access Denied", {
          //   position: "bottom-right",
          // });
          setAccessDenied(1)
          setInitialLoading(false)
        }else{
            toast.error("Something went wrong", {
            position: "bottom-right",
          });
        }
      } catch (error) {
        toast.error("Something went wrong", {
          position: "bottom-right",
        });
      }
    }

        const getProjectData = async()=>{
      setInitialLoading(true)
      try {
        const result = await fetchData(`admin/permissions/project-options`,'get',null,String(auth.user?.id_token),'')
        if(result.responseCode===200){
          setProjectOptions(result.response.data)
              // setDataList(result.response.data)
              setAccessDenied(2)
              setInitialLoading(false)
        }else if(result.responseCode===403){

          // setInitialLoading(true)
          // toast.error("Access Denied", {
          //   position: "bottom-right",
          // });
          setAccessDenied(1)
          setInitialLoading(false)
        }else{
            toast.error("Something went wrong", {
            position: "bottom-right",
          });
        }
      } catch (error) {
        toast.error("Something went wrong", {
          position: "bottom-right",
        });
      }
    }


    const headings = ['User Name','Project Name','Module Name','Actions']

    const actionOption = [
      {
      type: 2,
      link: "/admin/permission/edit",
      linkedField: "id",
      caption: "Edit Permission",
      permission_field: "perm_update"
      },
      {
    type: 3,
    link: "admin/permissions/delete",
    linkedField: "id",
    caption: "Delete Permission",
    permission_field: "perm_delete"
  }
]

  const moduleReformattedData=(data:ModulePermissionDataType[])=>{
    return data.filter(item=>item).map(item=>item.name).join(', ')
  }

    const generateRows= (data:PermissionType)=>{
      return(
        <>
          <td>{data.user?.email}</td>
          <td>{data.project?.name}</td>
          <td>{moduleReformattedData(data.module)}</td>

        </>
      )
    }

    const handleFilterData=(type:string|null=null)=>{

      if(type==='refresh'){
       let data = {project_id:null, email:null}
        getData(data)
          setSelectedData((prevData:any) => ({
                            project_id:null,
                            email: null,
                          }))

      }else{
        getData()
      }

    }
    return (

      <div className='fluid' style={{margin:'6%'}}>

      <CustomBreadcrumb list={[{name:'Home', url:'/home', isActive:false},{name:'All Permissions', url:'/admin/permissions', isActive:true}]}/>
      {/* Access Denied Message */}
      {accessDenied === 1 && !initialLoading && <AccessDenied url={"/home"} />}

      {/* Loading Spinner */}
      {initialLoading && (
        <div className="d-flex justify-content-center my-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      {/* Main Content */}
      {accessDenied === 2 && !initialLoading && (
        <>
          <Row className="align-items-center justify-content-center mb-4">
        <Col md={8}>
          <Form>
            <Row className="g-3 justify-content-center align-items-center">
              {/* Project Filter */}
              <Col md={4}>
                <Form.Group controlId="filterByProject">
                  <Form.Control
                    as="select"
                    value={selectedData.project_id || 0}
                    onChange={(e) =>
                      setSelectedData((prevData: any) => ({
                        ...prevData,
                        project_id: e.target.value,
                      }))
                    }
                  >
                    <option value={0}>All Projects</option>
                    {projectOptions.map((project: any, index) => (
                      <option key={index} value={project.project_id}>
                        {project.project_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              {/* Email Filter */}
              <Col md={4}>
                <Form.Group controlId="filterByEmail">
                  <Form.Control
                    type="text"
                    placeholder="Enter email"
                    value={selectedData.email || ""}
                    onChange={(e) =>
                      setSelectedData((prevData: any) => ({
                        ...prevData,
                        email: e.target.value,
                      }))
                    }
                  />
                </Form.Group>
              </Col>

              {/* Search and Refresh Buttons */}
              <Col md="auto">
                <Button
                  variant="primary m-1"
                  onClick={() => handleFilterData()}
                >
                  Search
                </Button>
                <Button
                  variant="primary m-1"
                  onClick={() => handleFilterData("refresh")}
                >
                  Refresh
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col md="auto">
          <Button
            variant="primary"
            onClick={() => history.push("/admin/permission/add")}
          >
            Add Permission
          </Button>
        </Col>
      </Row>

      {/* Toast Container */}
      <ToastContainer />

          {/* Custom Table */}
          {/* <div className=" justify-content-center"> */}
             {/* <div className="table-responsive" style={{ maxWidth: "90%" }}> */}
               <CustomTable
            headings={headings}
            generateRows={generateRows}
            data={dataList}
            setData={setDataList}
            routeLinks={actionOption}
          />
             {/* </div> */}

          {/* </div> */}

        </>
      )}
    </div>

  //      <div className='m-5'>
  //      {accessDenied===1 && !initialLoading && <AccessDenied url={"/home"} />}
  //      {initialLoading && <div className="spinner">
  //     <Spinner animation="border" role="status">
  //       <span className="visually-hidden">Loading...</span>
  //     </Spinner>
  //   </div> }
  //   {accessDenied===2 && !initialLoading && <>
  //  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '2%' }}>
  //   <div>
  //     <Button variant='primary' onClick={()=>history.push('/admin/permission/add')}>Add Permission</Button>
  //   </div>
  //   <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword" >
  //        <Form.Label column sm="2">
  //          Filter by Project
  //        </Form.Label>
  //        <Col sm="8">
  //        <Form.Control as="select" name="user"
  //        value={selectedData.project_id?selectedData.project_id:0}
  //        onChange={(e)=>setSelectedData((prevData:any)=>
  //        ({
  //         ...prevData,
  //         project_id:e.target.value
  //        })
  //       )}
  //       // value={permissionData.user_id}
  //       // onChange={(e)=>setPermissionData((prevData:PermissionDataType)=>{
  //       //   return{...prevData,user_id:parseInt(e.target.value)}
  //       // })}
  //       // disabled={type==='edit' || (type==='add' && id)?true:false}
  //       >
  //         <option>All Projects</option>
  //         {projectOptions.map((project:any,index)=><option key={index} value={project.project_id}>{project.project_name}</option>)}
  //       </Form.Control>
  //       </Col>
  //        <div >
  //         <Form.Label className="col-sm-6 col-form-label">
  //                       Project
  //                   </Form.Label>
  //           <Form.Control as="input" name="user" className="col-sm-3 d-flex"
  //           value={selectedData.email?selectedData.email:''}
  //            onChange={(e)=>setSelectedData((prevData:any)=>
  //        ({
  //         ...prevData,
  //         email:e.target.value
  //        })
  //       )}
  //         >
  //       </Form.Control>
  //         </div>
  //         <Button onClick={()=>handleFilterData()}>Search</Button>
  //   </Form.Group>
  //   <div>
  //     <Button onClick={()=>history.push('/admin')}>Go Back</Button>
  //   </div>
  // </div>
  //  <ToastContainer />
  //   <CustomTable headings={headings} generateRows={generateRows} data={dataList} setData={setDataList} routeLinks={actionOption}/>
  //     </>}
  //   </div>
    )
  }

export default AllPermission