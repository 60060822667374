import React, { useEffect, useState, useRef } from 'react'
import { Button, Col, Form, Row, Spinner} from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
// import { useAuth } from 'react-oidc-context'
import {useAuth} from '../../../context/useAuth'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import fetchData from '../../../helper/fetchdata'
import '../../../style/index.css'
import { useProjects } from '../../../context/ProjectContext'
import AccessDenied from '../../Pages/AccessDenied'
import { FcCheckmark } from "react-icons/fc";
import CustomBreadcrumb from '../../Pages/CustomBreadcrumb'


interface UserDataType{
  id:number,
  email:string
}

interface FinalDataType {
  user_id:number
  project:FinalModuleType[]
}

interface FinalModuleType {
  project_id:number
  modules: number[]|undefined
}
interface ModuleType {
  module_id: number,
  module_name: string,
  selected: boolean
}

// interface ProjectType {
//   [project_id: string]:ProjectData
// }
interface ProjectData {
  project_name: string;
  selected: boolean;
  module: ModuleType[];
}
type ProjectType = Record<string, ProjectData>;
interface PermissionDataType {
  user_id: number,
  project: ProjectType[]
}

function AddPermission() {
  const auth = useAuth()
  const formRef = useRef(null)
  const {type,id}:{type:string, id:string}= useParams()
  const history = useHistory()
  const data = useProjects();

  const [initialLoading, setInitialLoading] = useState(false)
  const [userData, setUserData] = useState<UserDataType[]>([])
  const [emailId, setEmailId] = useState('')
//set all the data(user, project, module) in a single state
  const [permissionData, setPermissionData] = useState<PermissionDataType>({user_id:0,project:[]})
  const [buttonLoading, setButtonLoading] = useState(false)
  const [accessDenied, setAccessDenied] = useState(3)
  const [isShowProject, setIsShowProject] = useState(false)

   useEffect(() => {
      //console.log(data)
      if(data?.initialLoading === 1 && !data?.isPermission){
         setAccessDenied(1)
         setInitialLoading(false)
      }
      if(data?.initialLoading === 1 && data?.isPermission){
         setAccessDenied(2)
          setInitialLoading(false)
        //getUserData()
        getProjectData()
      }
      if(data?.initialLoading === 3){
        setInitialLoading(true)
      }
    }, [data?.initialLoading])

  useEffect(() => {
    // console.log("params: ", params)
    //only get the data if there is a project->for edit page
    if(type==="edit" && data?.initialLoading === 1 && data?.isPermission){
      getData(id)
    }

    if(type==="add" && id && data?.initialLoading === 1 && data?.isPermission){
      // console.log("inside add section for a user id: ", id, auth.user?.profile)

      getUserInfo(id)
      // if(auth){
      //   setEmailId(auth.user?.profile.email as string)
      // setPermissionData({...permissionData})
      // setIsShowProject(true)
      // }

    }
  }, [permissionData.project.length>0])

  const getUserInfo = async(id:string)=>{
    try {
      setInitialLoading(true)
      const result = await fetchData(`users/get-user-Info/${id}`,'get',null,String(auth.user?.id_token),'')

      if(result.responseCode===200){
      setEmailId(result.response.data.email)
      permissionData.user_id= result.response.data.id
      setPermissionData({...permissionData})
      setIsShowProject(true)
      }else if (result.responseCode === 403) {
        setAccessDenied(1);
        setInitialLoading(false);
      }else{
        toast.error("Something went wrong", {
          position: "bottom-right",
        });
       }
    } catch (error) {
       toast.error("Something went wrong", {
        position: "bottom-right",
      });
    }
    setInitialLoading(false)
    //}
  }

  const getData = async(id:string)=>{
    setInitialLoading(true)
    try {
      const result = await fetchData(`admin/permissions/id/${id}`,'get',null,String(auth.user?.id_token),'')

      if(result.responseCode===200){
      const savedData = result.response.data[0]
      for (let index = 0; index < permissionData.project.length; index++) {
        if(permissionData.project[index][savedData.project.id]){
          handleAddData(savedData.project.id,0,'edit',index,0, savedData.module)
        }
      }
     permissionData.user_id=savedData.user.id
     setEmailId(savedData.user.email)
      setPermissionData({...permissionData})
      setIsShowProject(true)
       }else if (result.responseCode === 403) {
        setAccessDenied(1);
        setInitialLoading(false);
      }else{
        toast.error("Something went wrong", {
          position: "bottom-right",
        });
       }
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
      });
    }
    setInitialLoading(false)
  }


  // const getUserDataForAdmin = async(id?:string)=>{
  //   try {
  //     permissionData.user_id=34
  //     setPermissionData({...permissionData})
  //   } catch (error) {

  //   }
  // }
  // const getUserData= async(id?:string)=>{
  //   setInitialLoading(true)

  //   try {
  //     const result = await fetchData(`admin/permissions/user-options`,'get',null,String(auth.user?.id_token),'')

  //     if(result.responseCode===200){
  //       setUserData(result.response.data)
  //     }else{
  //       toast.error("Something went wrong", {
  //         position: "bottom-right",
  //       });
  //     }
  //   } catch (error) {
  //     toast.error("Something went wrong", {
  //       position: "bottom-right",
  //     });
  //   }
  //   setInitialLoading(false)
  // }

  const getProjectData = async()=>{
    try {
      setInitialLoading(true)
      const result = await fetchData(`admin/permissions/project-options`,'get',null,String(auth.user?.id_token),'')

      if(result.responseCode===200){
       const projectData= result.response.data

       if(permissionData && permissionData.project.length<=0){
       for (let index = 0; index < projectData.length; index++) {
          permissionData.project.push({[projectData[index].project_id]:{
            project_name:projectData[index].project_name,
            selected:false,
            module:[]
           }})
           }
        }
        setPermissionData({...permissionData})
      }else{
        toast.error("Something went wrong", {
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
      });
    }
    setInitialLoading(false)
  }

  const getModuleData = async(projectId:string, projectIndex:number,moduleArr:{id:number,name:string}[]=[])=>{
    try {
     const result = await fetchData(`admin/permissions/module-options/${projectId}`,'get',null,String(auth.user?.id_token),'')

      if(result.responseCode===200){
        let formattedData = []
        for (let index = 0; index < result.response.data.length; index++) {
            formattedData.push({
              module_id:result.response.data[index].module_id,
              module_name:result.response.data[index].module_name,
              selected:false
            })
            //if a certain module data is already selected in saved data->edit page
            if(type==='edit'){
              for (let i = 0; i < moduleArr.length; i++) {
                if(result.response.data[index].module_id===moduleArr[i].id){
                  formattedData[index].selected=true
                }
              }
            }
        }
        //store the module data under the particular project
        permissionData.project[projectIndex][projectId].module = formattedData
        setPermissionData({...permissionData})
      }else{
         toast.error("Something went wrong", {
                position: "bottom-right",
            });
      }
    } catch (error) {
       toast.error("Something went wrong", {
                position: "bottom-right",
            });
    }

  }


  const handleAddData = (projectId:string, moduleId:number,type:string, projectIndex:number,moduleIndex:number, moduleArr=[])=>{
    // Check if the project with the given id already exists, if yes set selected to false else true
    if(type==='project'){
      if(permissionData.project[projectIndex][projectId].selected===false){
        permissionData.project[projectIndex][projectId].selected = true
      }else{
        permissionData.project[projectIndex][projectId].selected = false
      }
      //get module data only after a project is selected
      getModuleData(projectId,projectIndex)
    }
    // Check if the module with the given id already exists, if yes set selected to false else true
    if(type==='module'){
      if(permissionData.project[projectIndex][projectId].module[moduleIndex].selected===false){
        permissionData.project[projectIndex][projectId].module[moduleIndex].selected=true
      }else{
        permissionData.project[projectIndex][projectId].module[moduleIndex].selected=false
      }
    }

    //to check if a certain project and module id already exists or not->for edit page
    if(type==='edit'){
      if(permissionData.project[projectIndex][projectId].selected===false){
        permissionData.project[projectIndex][projectId].selected = true
      }

      getModuleData(projectId,projectIndex,moduleArr)
    }
    setPermissionData({...permissionData})

      }

  //to get the selected(true) data and send it to the backend
const transformData=(originalData: PermissionDataType)=>{
        const transformedData: FinalDataType = {
          user_id: permissionData.user_id,
          project: []
        };

        //console.log("permissionData.user_id",permissionData.user_id)
        if(permissionData.user_id!==0 && originalData.project.length>0){

          originalData.project.forEach((projectObj: ProjectType) => {
          Object.entries(projectObj).forEach(([projectId, projectData]) => {
            if (projectData.selected) {
              const chosenProject = {
                project_id: Number(projectId),
                modules: projectData.module
                  .filter((module: ModuleType) => module.selected)
                  .map((module: ModuleType) => module.module_id)
              };
              transformedData.project.push(chosenProject);
            }
          });
        });

        return transformedData;
            // toast.error("Something went wrong", {
            //     position: "bottom-right",
            // });
        }else{
          return null
        }

      }

  const submitForm = async(e: React.FormEvent<HTMLFormElement>)=>{
    e.preventDefault()
    setButtonLoading(true)
    const finalData = transformData(permissionData)
    if(finalData && finalData.project.length>0){
      let url:string=`admin/permissions/add-permission`
      if(type==='edit'){
        url = `admin/permissions/edit-permission/${finalData.project[0].project_id}`
      }
      const result = await fetchData(url,'post',finalData,String(auth.user?.id_token),'json')

      //if the selected user has already access in the selected project list, it will show error
      if(result.responseCode===400){
        toast.error("Please select only those projects that the user is not currently assigned to.", {
          position: "bottom-right",
        });
      }else if (result.responseCode === 403) {
        setAccessDenied(1);
        setInitialLoading(false);
      }else if(result.responseCode===200 && result.response.data.length>0){
        if(auth.isAdmin){
           history.push('/admin/permission/all-user')
        }else{
           history.push('/admin/permission')
        }

        }else{
          toast.error("You can't give permission to this person again", {
            position: "bottom-right",
          });
        }
    }else{
      toast.error("Invalid Input, Please add valid data", {
        position: "bottom-right",
      });
    }
   setButtonLoading(false)
  }

  const handleGetUserId = async()=>{
    try {
      let url = `admin/permissions/get-user-for-permission`
      let finalData = JSON.stringify({
        email:emailId
      })
       const result = await fetchData(url,'post',finalData,String(auth.user?.id_token),'json')

       //console.log("result is: ", result)
       if(result.responseCode===200){
        if(result.response.data.id){
          setPermissionData((prevData:PermissionDataType)=>{
          return{...prevData,user_id:parseInt(result.response.data.id)}
        })
        setIsShowProject(true)
        }else{
           toast.error("User Not Found", {
        position: "bottom-right",
      });
        }

       }else if(result.responseCode===403){
      setAccessDenied(1);
        setInitialLoading(false);
       }else{
              toast.error("User Not Found", {
        position: "bottom-right",
      });
       }

    } catch (error) {
            toast.error("User Not Found", {
        position: "bottom-right",
      });
    }
  }

  const handleInputChange =(value:string)=>{
     setEmailId(value)
          permissionData.user_id=0
          setPermissionData({...permissionData})
          setIsShowProject(false)
  }

  return(
    <>{accessDenied===1 && !initialLoading && <AccessDenied url={"/home"} />}
    {initialLoading && <div className="spinner">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div> }
    {accessDenied===2 && !initialLoading &&
     <div className="container mt-5">
      <div
        className="d-flex justify-content-between align-items-center mb-4"
        style={{
          borderBottom: "1px solid #ddd",
          paddingBottom: "10px",
        }}
      >
    {auth.isAdmin?<CustomBreadcrumb list={[{name:'Home', url:'/home', isActive:false},{name:'All Users', url:'/admin/permission/all-user', isActive:false},{name:'Add Permissions', url:'/admin/permission/add', isActive:true}]}/>:<CustomBreadcrumb list={[{name:'Home', url:'/home', isActive:false},{name:'All Permissions', url:'/admin/permission', isActive:false},{name:`${type==='edit' ? "Edit" : "Add"} ${id ? " Add ":''}Permission`,isActive:true}]}/>}
    <h2 style={{ fontSize: "2em", margin: "10px 0", fontWeight: "bold" }}>
      {type==='edit' ? "Edit" : "Add"} Permission
    </h2>
    {/* <Button onClick={() => auth.isAdmin?history.push(`/admin/permission/all-user`):history.push(`/admin/permission`)}>Go Back</Button> */}
  </div>
   <ToastContainer/>
  <Form ref={formRef} onSubmit={(e) => submitForm(e)}>
             <Form.Group as={Row} className="mb-4 row" controlId="formPlaintextPassword" >
         <Form.Label column sm="2">
           User
         </Form.Label>
         {/* <Col sm="10"> */}
          <div className="col-sm-10 d-flex">
            <Form.Control as="input" name="user"
        value={emailId}
        onChange={(e)=>handleInputChange(e.target.value)}
        //value={permissionData.user_id}
        // onChange={(e)=>setPermissionData((prevData:PermissionDataType)=>{
        //   return{...prevData,user_id:parseInt(e.target.value)}
        // })}
        disabled={type==='edit' || (type==='add' && id)?true:false}></Form.Control>
        <div className='m-2'>
          {type!== 'edit' && permissionData.user_id!==0 ?<FcCheckmark />:<Button
       disabled={type==='edit' || !data?.isPermission}
       onClick={()=>handleGetUserId()}>Search</Button>}
        </div>

          </div>
         {/* for edit page data, user is disabled */}
         {/* <Form.Control as="select" name="user"
        value={permissionData.user_id}
        onChange={(e)=>setPermissionData((prevData:PermissionDataType)=>{
          return{...prevData,user_id:parseInt(e.target.value)}
        })}
        disabled={type==='edit' || (type==='add' && id)?true:false}
        >
          <option>Open this select menu</option>
          {userData.map((user,index)=><option key={index} value={user.id}>{user.email}</option>)}
        </Form.Control> */}

        {/* </Col> */}
        {isShowProject && <>
        <Form.Label column sm="2">
          Project
        </Form.Label>
        <Col sm="10">
        {/* map the permission data and if edit page the project and the user will be disabled so the admin can update the data for a paricular user and project */}
        {permissionData?.project.map((permission: ProjectType ,index: number)=>
          Object.entries(permission).map(([project_id, projectData]:[string, ProjectData]) => (
            <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Form.Check
              key={index}
                type="checkbox"
                id={`default-checkbox`}
                label={projectData.project_name}
                value={project_id}
                checked={projectData.selected}
                disabled={type==='edit'?true:false}
                onChange={(e)=>handleAddData(e.target.value, 0, 'project',index,0)}
              />
              {projectData.selected && projectData.module.map((moduleData: {module_name:string,module_id:number,selected:boolean},module_index: number)=>
              <Form.Check
              key={module_index}
              type="checkbox"
              id={`default-checkbox`}
              label={moduleData.module_name}
              value={moduleData.module_id}
              checked={moduleData.selected}
              onChange={(e)=>handleAddData(project_id, moduleData.module_id, 'module',index,module_index)}
            defaultChecked={moduleData.selected}
            />)}
            </div>
          ))
        )}
        </Col>
        </>}

      </Form.Group>

       <div className="d-flex justify-content-center align-items-center">
        {isShowProject &&<Button
        variant="primary"
            type="submit"
            disabled={buttonLoading}
            className="px-4 py-2"
        >{buttonLoading?<Spinner animation="border" role="status" size="sm">
  <span className="visually-hidden">Loading...</span>
</Spinner>:'Submit'}</Button>}
<Button
            variant="danger"
            className="px-4 py-2 mx-2"
            onClick={()=>auth.isAdmin?history.push('/admin/permission/all-user'):history.push('/admin/permission')}
          >
            Cancel
          </Button>
        </div>
    </Form>
   </div>

  }</>

  )
  }
export default AddPermission