import { useEffect, useState, FormEvent, useRef } from 'react'
import { Form, Button, Spinner, Overlay } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import ConvertURLtoCDN from '../../../helper/ConvertURLToCDN'
// import { useAuth } from 'react-oidc-context'
import {useAuth} from '../../../context/useAuth'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import fetchData from '../../../helper/fetchdata'
import { FcInfo } from "react-icons/fc";
import { useProjects } from '../../../context/ProjectContext'
import AccessDenied from '../../Pages/AccessDenied'
import CustomBreadcrumb from '../../Pages/CustomBreadcrumb'

interface ModuleType {
    //id:number,
    project_id: number,
    project_name: string,
    module_name: string,
    module_description:string
    module_api_url: string,
    module_id: number

}

interface ProjectType {
    id: number
    project_id: number,
    project_name: string
}

interface ProjectOptionType {
    project_id: number,
    project_name: string
}

function AddModule() {
    const auth = useAuth()
    const history = useHistory()
    const formRef = useRef(null)
    const data = useProjects();
    const params: { [key: string]: string | undefined } = useParams()
    const [moduleData, setModuleData] = useState<ModuleType>()
    const [projectOptions, setProjectOptions] = useState<ProjectOptionType[]>([])
    const [buttonLoading, setButtonLoading] = useState(false)
    const [apiUrl, setApiUrl] = useState<URL | string>('')
    const [initialLoading, setInitialLoading] = useState(false)
    const [show, setShow] = useState(false);
    const [accessDenied, setAccessDenied] = useState(3)
    const target = useRef(null);

    useEffect(() => {
      if(params.id && data?.initialLoading === 1 && data?.isSettings){
          getData(params.id)
          getAllProjectOptions()
      }
      //console.log(data)
      if(data?.initialLoading === 1 && !data?.isSettings){
         setAccessDenied(1)
         setInitialLoading(false)
      }
      if(data?.initialLoading === 1 && data?.isSettings){
         setAccessDenied(2)
          setInitialLoading(false)
          getAllProjectOptions()
      }
      if(data?.initialLoading === 3){
        setInitialLoading(true)
      }
    }, [data?.initialLoading])


    const getData = async (id: string) => {
        try {
            setInitialLoading(true)
            const result = await fetchData(`admin/modules/id/${id}`, 'get', null, String(auth.user?.id_token), null)
            if (result.responseCode === 200) {
                setApiUrl(result.response.data[0].module_api_url)
                setModuleData(result.response.data[0])
                setInitialLoading(false)
            } else if (result.responseCode === 403) {
        setAccessDenied(1);
        setInitialLoading(false);
      } else {
                toast.error("Something went wrong", {
                    position: "bottom-right",
                });
            }
        } catch (error) {
            toast.error("Something went wrong", {
                position: "bottom-right",
            });
        }
    }

    const getAllProjectOptions = async () => {
        try {
            setInitialLoading(true)
            const result = await fetchData(`admin/modules/projects/options`, 'get', null, String(auth.user?.id_token), null)
            if (result.responseCode === 200) {
                setProjectOptions(result.response.data)
                setInitialLoading(false)
            } else {
                toast.error("Something went wrong", {
                    position: "bottom-right",
                });
            }
        } catch (error) {
            toast.error("Something went wrong", {
                position: "bottom-right",
            });
        }
        setInitialLoading(false)
    }

    const submitForm = async (e: FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault()
            setButtonLoading(true)
            if (formRef.current) {
                const formData = new FormData(formRef.current)
                let data: { [key: string]: any } = {};
                formData.forEach(function (value, key) {
                    data[key] = value;

                });
                data['api_url'] = apiUrl

                let url: string = `admin/modules/add-module`
                if (params.id) {
                    url = `admin/modules/edit-module/${params.id}`
                }

                const result = await fetchData(url, 'post', data, String(auth.user?.id_token), 'json')
                if (result.responseCode === 200) {
                    history.push('/admin/project')
                    setButtonLoading(false)
                }else if (result.responseCode === 403) {
        setAccessDenied(1);
        setInitialLoading(false);
      }else {
                    toast.error("Something went wrong", {
                        position: "bottom-right",
                    });
                }
            }
        } catch (error) {
            toast.error("Something went wrong", {
                position: "bottom-right",
            });
        }
    }

    const handleFileChange = async (e: any) => {
        try {
            //setInitialLoading(true)
            // const target = e.target as HTMLInputElement;
            const formData = new FormData()
            // if(e.target.files)
            formData.append('file', e.target.files[0])

            let url: string = `${process.env.REACT_APP_BACKEND_URL}/api/admin/modules/upload-file`
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + `${auth.user?.id_token}`,
                },
                body: formData
            }).then((res) => res.json())


            if (res.success) {
                onSuccess(res.data)
                //setInitialLoading(false)
                toast.success("File Uploaded Successfully", {
                    position: "bottom-right",
                });

            } else {
                toast.error("Something went wrong", {
                    position: "bottom-right",
                });

            }
        } catch (error) {
            toast.error("Something went wrong", {
                position: "bottom-right",
            });
        }
    };



    const onSuccess = (data: { [key: string]: any }) => {
        if (data) {
            let url = ConvertURLtoCDN(data);
            if (url) {
                setApiUrl(url)
            }

        }
    }


    return (
              <>
  {accessDenied === 1 && !initialLoading && <AccessDenied url={"/home"} />}
  {initialLoading && (
    <div className="spinner d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  )}
  {accessDenied === 2 && !initialLoading && (
    <div className="container mt-5">
      {/* Breadcrumb and Page Title */}
      <div
        className="d-flex justify-content-between align-items-center mb-4"
        style={{
          borderBottom: "1px solid #ddd",
          paddingBottom: "10px",
        }}
      >
        <CustomBreadcrumb
          list={[
            { name: "Home", url: "/home", isActive: false },
            { name: "Admin", url: "/admin", isActive: false },
            {
              name: "All Projects",
              url: "/admin/project",
              isActive: false,
            },
            { name: params.id ? "Edit Module" : "Add Module", isActive: true },
          ]}
        />
        <h2 style={{ fontSize: "1.8em", fontWeight: "600", margin: 0 }}>
          {params.id ? "Edit Module" : "Add Module"}
        </h2>
      </div>

      {/* Toast Notification */}
      <ToastContainer />

      {/* Form */}
      <Form
        ref={formRef}
        onSubmit={(e) => submitForm(e)}
        style={
          initialLoading
            ? { pointerEvents: "none", opacity: 0.6 }
            : { pointerEvents: "auto" }
        }
        className="p-4 rounded shadow-sm bg-light"
      >
        {/* Module Name */}
        <Form.Group className="mb-4 row">
          <Form.Label className="col-sm-2 col-form-label">
            Module Name
          </Form.Label>
          <div className="col-sm-10">
            <Form.Control
              type="text"
              name="name"
              defaultValue={params.id && moduleData ? moduleData.module_name : ""}
              placeholder="Enter module name"
              required
            />
          </div>
        </Form.Group>

        {/* Project Selector */}
        <Form.Group className="mb-4 row">
          <Form.Label className="col-sm-2 col-form-label">Project</Form.Label>
          <div className="col-sm-10">
            <Form.Select
              name="project_id"
              value={
                params.id && moduleData ? moduleData.project_id : undefined
              }
              onChange={(e) =>
                setModuleData((prevData: ModuleType | undefined) => {
                  if (prevData) {
                    return {
                      ...prevData,
                      project_id: Number(e.target.value),
                    };
                  }
                })
              }
              required
            >
              <option value="">Select a project</option>
              {projectOptions.map((project, index) => (
                <option value={project.project_id} key={index}>
                  {project.project_name}
                </option>
              ))}
            </Form.Select>
          </div>
        </Form.Group>

        {/* Module Description */}
        <Form.Group className="mb-4 row">
          <Form.Label className="col-sm-2 col-form-label">
            Module Description
          </Form.Label>
          <div className="col-sm-10">
            <Form.Control
              as="textarea"
              rows={4}
              name="description"
              defaultValue={
                params.id && moduleData ? moduleData.module_description : ""
              }
              placeholder="Enter module description"
              required
            />
          </div>
        </Form.Group>

        {/* API Data URL */}

        <Form.Group className="mb-4 row align-items-center">

          <Form.Label className="col-sm-2 col-form-label">
            <span>API Data URL</span>  <span ref={target} onMouseOver={() => setShow(true)} onMouseOut={() => setShow(false)} style={{  cursor: 'pointer' }}><FcInfo /></span>
          </Form.Label>
{/* margin: '10px 5px 0 5px', */}
          <div className="col-sm-10 d-flex gap-3 align-items-center">
            <Form.Control
              type="file"
              onChange={(e) => handleFileChange(e)}
              className="w-50"
            />
            <Form.Control
              type="text"
              name="api_url"
              placeholder="Enter API URL"
              defaultValue={apiUrl as string}
              className="w-50"
              required
            />

                            <Overlay target={target.current} show={show} placement="right">
                                {({
                                    placement: _placement,
                                    arrowProps: _arrowProps,
                                    show: _show,
                                    popper: _popper,
                                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                                    ...props
                                }) => (
                                    <div
                                        {...props}
                                        style={{
                                            position: 'absolute',
                                            backgroundColor: 'rgb(51, 153, 255)',
                                            padding: '15px',
                                            color: 'white',
                                            borderRadius: 16,
                                            ...props.style,
                                        }}
                                    >
                                        <p style={{ fontWeight: 'bold' }}>How to save the Postman JSON Collection</p>
                                        <p><strong>Step 1:</strong> Open Postman on your computer.</p>
                                        <p><strong>Step 2:</strong> In the left sidebar, click on the collection you want to export.</p>
                                        <p><strong>Step 3:</strong> Next to the collection name, click on the three dots to open a dropdown menu.</p>
                                        <p><strong>Step 4:</strong> In the dropdown menu, select the 'Export' option.</p>
                                        <p><strong>Step 5:</strong> Choose 'Collection v2.1 (recommended)' as the version.</p>
                                        <p><strong>Step 6:</strong> Click 'Export' and choose a location on your computer to save the file.</p>
                                    </div>
                                )}
                            </Overlay>
                       </div>
        </Form.Group>

        {/* Submit Button */}
        <div className="d-flex justify-content-center align-items-center">
          <Button
            variant="primary"
            type="submit"
            disabled={buttonLoading}
            className="px-4 py-2"
          >
            {buttonLoading ? (
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            variant="danger"
            className="px-4 py-2 mx-2"
            onClick={()=>history.push('/admin/project')}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  )}
</>

    )
}

export default AddModule