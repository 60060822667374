import { Breadcrumb, Button, Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useProjects } from '../../context/ProjectContext';
import AccessDenied from '../Pages/AccessDenied';
import { useEffect, useState } from 'react';
import { useAuth } from '../../context/useAuth';
import CustomBreadcrumb from '../Pages/CustomBreadcrumb';

function Admin() {
  const history = useHistory();
  const data = useProjects();
  const auth = useAuth()
  const [accessDenied, setAccessDenied] = useState(3)

  useEffect(() => {
    // console.log(data)

if(data?.initialLoading===1 && !data?.isSettings && !data?.isPermission){
  setAccessDenied(1)
}

  }, [data?.initialLoading])


  return (
    <Container style={{ marginTop: "7%" }}>
      <CustomBreadcrumb list={[{name:'Home', url:'/home', isActive:false},{name:'Admin', url:'/admin', isActive:true}]}/>
      <Row className="justify-content-center">
        {data?.initialLoading === 3 && (
          <Col xs={12} className="text-center mb-4">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        )}

        {data?.initialLoading === 1 && data?.isSettings && (
          <Col md={4} className="d-flex justify-content-center mb-4">
            <Card style={{  width: "30rem",
                  height:"15rem", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
              <Card.Body className="m-3 text-center">
                <Card.Title className="font-weight-bold">Projects</Card.Title>
                <Card.Text className="mt-4">Manage all your projects here.</Card.Text>
                <Button
                  variant="primary"
                  className="mt-5"
                  onClick={() => history.push("/admin/project")}
                >
                  Go Project
                </Button>
              </Card.Body>
            </Card>
          </Col>
        )}

        {!auth.isAdmin && data?.initialLoading === 1 && data?.isPermission && (
          <Col md={4} className="d-flex justify-content-center mb-4">
            <Card style={{   width: "30rem",
                  height:"15rem", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
              <Card.Body className="m-4 text-center">
                <Card.Title>Permissions</Card.Title>
                <Card.Text className="mt-4">Manage permissions for users.</Card.Text>
                <Button
                  variant="primary"
                  className="mt-5"
                  onClick={() => history.push("/admin/permission")}
                >
                  Go Permission
                </Button>
              </Card.Body>
            </Card>
          </Col>
        )}

        {data?.initialLoading === 1 && accessDenied === 1 && (
          <Col xs={12} className="text-center mb-4">
            <AccessDenied url={"/home"} />
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default Admin;


