import { Redirect, Route, RouteComponentProps, Switch} from 'react-router-dom';
import Login from '../components/LoginPage';
import StoplightAPI from '../components/Pages/StoplightApi';
import Home from '../components/Pages/Home'
import Admin from '../components/Admin/Admin';
import AllProjects from '../components/Admin/Project/AllProjects';
import AllModules from '../components/Admin/Module/AllModules';
import AllPermission from '../components/Admin/Permission/AllPermission';
import AddPermission from '../components/Admin/Permission/AddPermission';
import AddProject from '../components/Admin/Project/AddProject';
import AddModule from '../components/Admin/Module/AddModule';
import ProjectPage from '../components/Pages/ProjectPage';
//import { useAuth } from 'react-oidc-context';
import LoginSuccess from '../components/LoginSuccess';
import { ProjectProvider } from '../context/ProjectContext';
import AllUserPermission from '../components/Admin/Permission/AllUserPermission';
// import { useCustomAuth } from '../context/AuthContext';
import PermissionDataByUsers from '../components/Admin/Permission/PermissionDataByUser';
import {useAuth} from '../context/useAuth'

interface ProtectedRouteProps {
  component: React.ComponentType<RouteComponentProps>;
  [x: string]: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => {
  const auth = useAuth()//{isLoading: false, isAuthenticated: true, user: User, error: undefined, settings: UserManagerSettingsStore, …}
 // const {extendedAuth: auth} = useCustomAuth()//{isLoading: true, isAuthenticated: false, settings: UserManagerSettingsStore, events: UserManagerEvents, clearStaleState: ƒ, …}
  //console.log("auth is:", auth)
  // console.log("auth1 is:", auth1)
    // console.log(auth.user)

  return (
    // <Route
    //   {...rest}
    //   render={props =>
    //     auth.isAuthenticated===true && auth.isLoading===false?
    //      (
    //             <ProjectProvider>
    //                <Component {...props} />
    //             </ProjectProvider>
    //     ) : auth.isAuthenticated===false && auth.isLoading===true && (
    //       <Redirect to="/login" />
    //     )
    //   }
    // />

    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated && !auth.isLoading?
         (
                <ProjectProvider>
                   <Component {...props} />
                </ProjectProvider>

        ) : !auth.isAuthenticated && !auth.isLoading && (
          <Redirect to="/login" />
        )
      }
    />
  );
};


const RouteMain =()=>{
  return(
    <Switch>

       <Route path="/login" component={Login}/>
       <Route path="/login-success" component={LoginSuccess} />
       <ProtectedRoute path="/home" component={Home} />
       <ProtectedRoute path="/project/:id" component={ProjectPage}/>
       <ProtectedRoute path="/admin/project/add" component={AddProject}/>
       <ProtectedRoute path="/admin/project/edit/:id" component={AddProject}/>
       <ProtectedRoute path="/admin/project/:id" component={AllModules}/>
       <ProtectedRoute path="/admin/project" component={AllProjects}/>
       <ProtectedRoute path="/admin/module/add/project-id/:project_id" component={AddModule}/>
       <ProtectedRoute path="/admin/module/edit/:id" component={AddModule}/>
       <ProtectedRoute path="/admin/module/project-id/:id" component={AllModules}/>
       <ProtectedRoute path="/module/id/:id" component={StoplightAPI} />
       <ProtectedRoute path="/admin/permission/view/:id" component={PermissionDataByUsers}/>
       <ProtectedRoute path="/admin/permission/all-user" component={AllUserPermission}/>
       <ProtectedRoute path="/admin/permission/:type/:id" component={AddPermission}/>
       <ProtectedRoute path="/admin/permission/:type" component={AddPermission}/>
       <ProtectedRoute path="/admin/permission" component={AllPermission}/>
       <ProtectedRoute path="/admin" component={Admin}/>
       <ProtectedRoute path="/" component={Home}/>
    </Switch>
  )
}

export default RouteMain