import { Navbar, Container, Button, Nav } from 'react-bootstrap';
//import { useAuth } from 'react-oidc-context';
import { useProjects } from '../context/ProjectContext';
// import { useCustomAuth } from '../context/AuthContext';
//import useCustomAuthStore from '../context/customAuthStore'
import {useAuth} from '../context/useAuth'

export const Navigation = () => {
   const auth = useAuth()
  // const data = useProjects()
  //const auth = useCustomAuth()

//console.log("data in nav bar: ", auth)

  return (

    <Navbar className="bg-dark text-light">

    <Container>
    <img className="stockedgelogo" style={{ "width": "60px",
    "height": "auto", marginRight:'20px'}} src="https://duzycfafl38re.cloudfront.net/Website/Images/stockedgelogoimage28102022144458.png" width="60" height="60" alt="StockEdge"></img>
      <Navbar.Brand href="#home" className="text-light">

      </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto text-light">
            <Nav.Link className="text-light" href="/home">Home</Nav.Link>
          {/* <Nav.Link className="text-light" href="/admin/permission">Permission</Nav.Link>
            <Nav.Link className="text-light" href="/admin/permission/all-user">Users</Nav.Link> */}
             {auth.isAuthenticated && auth.isAdmin && <Nav.Link className="text-light" href="/admin/permission/all-user">Users</Nav.Link>}
           {auth.isAuthenticated && !auth.isAdmin && <Nav.Link className="text-light" href="/admin/permission">Permission</Nav.Link>}

          </Nav>
        </Navbar.Collapse>
        <Nav className="me-auto">
          {auth.isAuthenticated && !auth.isLoading ? <Button variant="danger" onClick={()=>auth.signoutRedirect({ 'post_logout_redirect_uri': `${process.env.REACT_APP_REDIRECT_URI}/login` })}>Logout</Button>: <Button variant="primary" onClick={() => auth.signinRedirect()}>
                  Login
                </Button>}</Nav>
    </Container>
  </Navbar>

  //  <Navbar className="bg-dark text-light">
  //     <Container>
  //       <img className="stockedgelogo" style={{ width: "60px", height: "auto", marginRight: '20px' }} src="" alt=""></img>
  //       <Navbar.Brand href="#home" className="text-light"></Navbar.Brand>
  //       <Navbar.Collapse id="basic-navbar-nav">
  //         <Nav className="me-auto text-light">
  //           <Nav.Link className="text-light" href="/home">Home</Nav.Link>
  //           {auth.isAuthenticated && auth.isAdmin && <Nav.Link className="text-light" href="/admin/permission/all-user">Users</Nav.Link>}
  //           {auth.isAuthenticated && !auth.isAdmin && <Nav.Link className="text-light" href="/admin/permission">Permission</Nav.Link>}
  //         </Nav>
  //       </Navbar.Collapse>
  //       <Nav className="me-auto">
  //         {auth.isAuthenticated && !auth.isLoading ? (
  //           <Button variant="danger" onClick={() => auth.signoutRedirect({ 'post_logout_redirect_uri': `${process.env.REACT_APP_REDIRECT_URI}/login` })}>Logout</Button>
  //         ) : (
  //           <Button variant="primary" onClick={() => auth.signinRedirect()}>Login</Button>
  //         )}
  //       </Nav>
  //     </Container>
  //   </Navbar>
  );
};
